var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login1" },
    [
      _c("div", { staticClass: "login-form" }, [
        _c("div", { staticClass: "logo-info" }, [
          _c("img", {
            staticClass: "logo-image",
            attrs: {
              src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/pc_logo.png",
            },
          }),
          _vm.isTestVersion
            ? _c("span", { staticStyle: { color: "#f00000" } }, [
                _vm._v("（测试版）"),
              ])
            : _vm._e(),
        ]),
        _vm.isForgetPsd
          ? _c("div", { staticClass: "login-container" }, [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "100%",
                    padding: "0 30px",
                    "box-sizing": "border-box",
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "forgetPwdForm",
                      attrs: {
                        model: _vm.forgetPwdForm,
                        rules: _vm.forgetPwdFormRules,
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "mobile" } },
                        [
                          _c("el-input", {
                            attrs: {
                              "prefix-icon": "el-icon-mobile",
                              type: "text",
                              "auto-complete": "off",
                              placeholder: "请输入手机号",
                            },
                            model: {
                              value: _vm.forgetPwdForm.mobile,
                              callback: function ($$v) {
                                _vm.$set(_vm.forgetPwdForm, "mobile", $$v)
                              },
                              expression: "forgetPwdForm.mobile",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "code" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "calc(100% - 120px)" },
                            attrs: {
                              "prefix-icon": "el-icon-message",
                              "auto-complete": "off",
                              placeholder: "请输入手机验证码",
                            },
                            model: {
                              value: _vm.forgetPwdForm.code,
                              callback: function ($$v) {
                                _vm.$set(_vm.forgetPwdForm, "code", $$v)
                              },
                              expression: "forgetPwdForm.code",
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "fr send-code" },
                            [
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isGetForgetPwdCode,
                                      expression: "isGetForgetPwdCode",
                                    },
                                  ],
                                  staticStyle: {
                                    width: "100px",
                                    padding: "10px 0",
                                    height: "32px",
                                    "margin-top": "2px",
                                    "line-height": "10px",
                                    "box-sizing": "border-box",
                                  },
                                  attrs: {
                                    plain: "",
                                    size: "medium",
                                    type: "primary",
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.getForgetPwdCode($event)
                                    },
                                  },
                                },
                                [_vm._v("获取验证码")]
                              ),
                              _c(
                                "el-button",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.isGetForgetPwdCode,
                                      expression: "!isGetForgetPwdCode",
                                    },
                                  ],
                                  staticStyle: {
                                    width: "100px",
                                    padding: "10px 0",
                                    height: "32px",
                                    "margin-top": "2px",
                                    "line-height": "10px",
                                    "box-sizing": "border-box",
                                  },
                                  attrs: {
                                    plain: "",
                                    size: "medium",
                                    type: "primary",
                                    disabled: (_vm.disabled =
                                      !_vm.isGetForgetPwdCode),
                                  },
                                },
                                [_vm._v(_vm._s(_vm.forgetPwdCount) + "s后重试")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "password" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "password",
                              "prefix-icon": "el-icon-key",
                              "auto-complete": "off",
                              placeholder: "请重新输入密码",
                            },
                            model: {
                              value: _vm.forgetPwdForm.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.forgetPwdForm, "password", $$v)
                              },
                              expression: "forgetPwdForm.password",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "confirmPassword" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "password",
                              "prefix-icon": "el-icon-key",
                              "auto-complete": "off",
                              placeholder: "请再次输入密码",
                            },
                            model: {
                              value: _vm.forgetPwdForm.confirmPassword,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.forgetPwdForm,
                                  "confirmPassword",
                                  $$v
                                )
                              },
                              expression: "forgetPwdForm.confirmPassword",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { staticStyle: { width: "100%" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { size: "medium", type: "primary" },
                              nativeOn: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.onResetPwdCLick($event)
                                },
                              },
                            },
                            [_vm._v("确认")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100%",
                        margin: "0 auto 35px",
                        "text-align": "center",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "fr",
                          staticStyle: { cursor: "pointer" },
                          attrs: { type: "text" },
                          on: { click: _vm.onToLoginClick },
                        },
                        [_vm._v("去登录")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ])
          : _c(
              "div",
              { staticClass: "login-container" },
              [
                _vm.isLogin
                  ? [
                      _c("ul", { staticClass: "login-tab" }, [
                        _c(
                          "li",
                          {
                            class: { active: _vm.cur == 1 },
                            on: {
                              click: function ($event) {
                                return _vm.loginTabClick(1)
                              },
                            },
                          },
                          [_vm._v("账号登录")]
                        ),
                        _c(
                          "li",
                          {
                            class: { active: _vm.cur == 2 },
                            on: {
                              click: function ($event) {
                                return _vm.loginTabClick(2)
                              },
                            },
                          },
                          [_vm._v("扫码登录")]
                        ),
                        _c(
                          "li",
                          {
                            class: { active: _vm.cur == 3 },
                            on: {
                              click: function ($event) {
                                return _vm.loginTabClick(3)
                              },
                            },
                          },
                          [_vm._v("手机号登录")]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.cur == 1,
                              expression: "cur==1",
                            },
                          ],
                          staticClass: "code-login",
                        },
                        [
                          _c(
                            "el-form",
                            {
                              ref: "loginForm",
                              attrs: {
                                model: _vm.loginForm,
                                rules: _vm.loginRules,
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "username" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "prefix-icon": "el-icon-user",
                                      type: "text",
                                      "auto-complete": "off",
                                      placeholder: "请输入账号",
                                    },
                                    model: {
                                      value: _vm.loginForm.username,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.loginForm, "username", $$v)
                                      },
                                      expression: "loginForm.username",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { prop: "password" } },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      "prefix-icon": "el-icon-key",
                                      type: "password",
                                      "auto-complete": "off",
                                      placeholder: "请输入密码",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.handleLogin($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.loginForm.password,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.loginForm, "password", $$v)
                                      },
                                      expression: "loginForm.password",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.isShowCode
                                ? _c(
                                    "el-form-item",
                                    { attrs: { prop: "code" } },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "63%" },
                                        attrs: {
                                          "auto-complete": "off",
                                          placeholder: "请输入验证码",
                                        },
                                        model: {
                                          value: _vm.loginForm.code,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.loginForm, "code", $$v)
                                          },
                                          expression: "loginForm.code",
                                        },
                                      }),
                                      _c("div", { staticClass: "login-code" }, [
                                        _c("img", {
                                          staticClass: "login-code-img",
                                          attrs: { src: _vm.codeUrl },
                                          on: { click: _vm.getCode },
                                        }),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-form-item",
                                { staticStyle: { width: "100%" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { width: "100%" },
                                      attrs: {
                                        loading: _vm.loading,
                                        size: "medium",
                                        type: "primary",
                                      },
                                      nativeOn: {
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.handleLogin($event)
                                        },
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleLogin($event)
                                        },
                                      },
                                    },
                                    [
                                      !_vm.loading
                                        ? _c("span", [_vm._v("登 录")])
                                        : _c("span", [_vm._v("登 录 中...")]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "100%",
                                margin: "0 auto 35px",
                                "text-align": "center",
                                height: "22px",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "fl",
                                  staticStyle: {
                                    "font-size": "14px",
                                    color: "#394349",
                                    "line-height": "22px",
                                    cursor: "pointer",
                                  },
                                  on: { click: _vm.onForgetPsdClick },
                                },
                                [_vm._v("忘记密码")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "fr",
                                  staticStyle: {
                                    "font-size": "14px",
                                    color: "#394349",
                                    "line-height": "22px",
                                    cursor: "pointer",
                                  },
                                  on: { click: _vm.onRegisterClick },
                                },
                                [
                                  _vm._v(" 没有账号？"),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        margin: "0",
                                        padding: "0",
                                      },
                                      attrs: { type: "text" },
                                    },
                                    [_vm._v("立即注册")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.cur == 2,
                              expression: "cur==2",
                            },
                          ],
                          staticClass: "qr-login",
                        },
                        [
                          _c("img", {
                            style: [
                              { width: _vm.isTestVersion ? "210px" : "" },
                              { height: _vm.isTestVersion ? "auto" : "" },
                            ],
                            attrs: { src: _vm.qrImg },
                            on: { click: _vm.refreshQrImg },
                          }),
                          _vm.isTestVersion
                            ? _c("p", { staticStyle: { color: "#f00000" } }, [
                                _vm._v("测试环境不生成小程序码"),
                              ])
                            : _c(
                                "p",
                                {
                                  style: {
                                    color: _vm.isValid ? "#f00000" : "",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      !_vm.isValid
                                        ? "请使用微信扫码登录"
                                        : "二维码已失效，请点击更换"
                                    )
                                  ),
                                ]
                              ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.cur == 3,
                              expression: "cur==3",
                            },
                          ],
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "phone-login" },
                            [
                              _c(
                                "el-form",
                                {
                                  ref: "mobileLoginForm",
                                  attrs: {
                                    model: _vm.mobileLoginForm,
                                    rules: _vm.mobileLoginRules,
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "mobile" } },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "text",
                                          "prefix-icon": "el-icon-mobile",
                                          placeholder: "请输入手机号",
                                        },
                                        model: {
                                          value: _vm.mobileLoginForm.mobile,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.mobileLoginForm,
                                              "mobile",
                                              $$v
                                            )
                                          },
                                          expression: "mobileLoginForm.mobile",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "smsCode" } },
                                    [
                                      _c("el-input", {
                                        staticStyle: {
                                          width: "calc(100% - 120px)",
                                        },
                                        attrs: {
                                          "prefix-icon": "el-icon-message",
                                          placeholder: "请输入验证码",
                                          maxlength: 6,
                                        },
                                        model: {
                                          value: _vm.mobileLoginForm.smsCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.mobileLoginForm,
                                              "smsCode",
                                              $$v
                                            )
                                          },
                                          expression: "mobileLoginForm.smsCode",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "fr send-code" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.isGetCode,
                                                  expression: "isGetCode",
                                                },
                                              ],
                                              attrs: {
                                                plain: "",
                                                type: "primary",
                                              },
                                              nativeOn: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.getMobileLoginCode(
                                                    $event
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("获取验证码")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: !_vm.isGetCode,
                                                  expression: "!isGetCode",
                                                },
                                              ],
                                              attrs: {
                                                plain: "",
                                                type: "primary",
                                                disabled: (_vm.disabled =
                                                  !_vm.isGetCode),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.count) + "s后重试"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "margin-bottom": "30px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            loading: _vm.loading,
                                            size: "medium",
                                            type: "primary",
                                          },
                                          nativeOn: {
                                            keyup: function ($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.handleMobileLogin(
                                                $event
                                              )
                                            },
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.handleMobileLogin(
                                                $event
                                              )
                                            },
                                          },
                                        },
                                        [
                                          !_vm.loading
                                            ? _c("span", [_vm._v("登 录")])
                                            : _c("span", [
                                                _vm._v("登 录 中..."),
                                              ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  : [
                      _c(
                        "el-form",
                        {
                          ref: "registerForm",
                          staticClass: "register-form",
                          attrs: {
                            model: _vm.registerForm,
                            rules: _vm.registerFormRules,
                            "validate-on-rule-change": false,
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "register-select" },
                            [
                              [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: "phone" },
                                    model: {
                                      value: _vm.registerForm.type,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.registerForm, "type", $$v)
                                      },
                                      expression: "registerForm.type",
                                    },
                                  },
                                  [_vm._v("手机号注册")]
                                ),
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: "email" },
                                    model: {
                                      value: _vm.registerForm.type,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.registerForm, "type", $$v)
                                      },
                                      expression: "registerForm.type",
                                    },
                                  },
                                  [_vm._v("邮箱注册")]
                                ),
                              ],
                            ],
                            2
                          ),
                          _c(
                            "el-form-item",
                            { ref: "userName", attrs: { prop: "username" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  "prefix-icon":
                                    _vm.registerForm.type == "phone"
                                      ? "el-icon-mobile"
                                      : "el-icon-position",
                                  type: "text",
                                  "auto-complete": "off",
                                  placeholder:
                                    _vm.registerForm.type == "phone"
                                      ? "请输入注册手机号"
                                      : "请输入注册邮箱",
                                },
                                model: {
                                  value: _vm.registerForm.username,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.registerForm, "username", $$v)
                                  },
                                  expression: "registerForm.username",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "code" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "calc(100% - 120px)" },
                                attrs: {
                                  "prefix-icon": "el-icon-message",
                                  "auto-complete": "off",
                                  placeholder: "请输入验证码",
                                },
                                model: {
                                  value: _vm.registerForm.code,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.registerForm, "code", $$v)
                                  },
                                  expression: "registerForm.code",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "fr" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.isGetCode,
                                          expression: "isGetCode",
                                        },
                                      ],
                                      attrs: { plain: "", type: "primary" },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.getRegisterPhY($event)
                                        },
                                      },
                                    },
                                    [_vm._v("获取验证码")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: !_vm.isGetCode,
                                          expression: "!isGetCode",
                                        },
                                      ],
                                      attrs: {
                                        plain: "",
                                        type: "primary",
                                        disabled: (_vm.disabled =
                                          !_vm.isGetCode),
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.count) + "s后重试")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "password" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  "prefix-icon": "el-icon-key",
                                  type: "password",
                                  "auto-complete": "new-password",
                                  placeholder: "请输入密码",
                                },
                                model: {
                                  value: _vm.registerForm.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.registerForm, "password", $$v)
                                  },
                                  expression: "registerForm.password",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "confirmPassword" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  "prefix-icon": "el-icon-key",
                                  type: "password",
                                  "auto-complete": "off",
                                  placeholder: "请再次输入密码",
                                },
                                model: {
                                  value: _vm.registerForm.confirmPassword,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.registerForm,
                                      "confirmPassword",
                                      $$v
                                    )
                                  },
                                  expression: "registerForm.confirmPassword",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              style: !_vm.registerAgreementCheck
                                ? "margin-bottom: 0px"
                                : "",
                              attrs: { prop: "registerAgreement" },
                            },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.registerForm.registerAgreement,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.registerForm,
                                        "registerAgreement",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "registerForm.registerAgreement",
                                  },
                                },
                                [_vm._v("我已阅读并同意")]
                              ),
                              _c(
                                "el-link",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    "vertical-align": "top",
                                  },
                                  attrs: { type: "primary", underline: false },
                                  on: {
                                    click: function ($event) {
                                      _vm.serviceAgreementDialog = true
                                    },
                                  },
                                },
                                [_vm._v("《服务条款》")]
                              ),
                              _c(
                                "el-link",
                                {
                                  staticStyle: {
                                    "font-size": "12px",
                                    "vertical-align": "top",
                                  },
                                  attrs: { type: "primary", underline: false },
                                  on: {
                                    click: function ($event) {
                                      _vm.privacyClauseDialog = true
                                    },
                                  },
                                },
                                [_vm._v("《隐私条款》")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { staticStyle: { width: "100%" } },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { type: "primary" },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.handleRegister($event)
                                    },
                                  },
                                },
                                [_vm._v("快速注册")]
                              ),
                              _c(
                                "p",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        display: "inline-block",
                                        "font-size": "14px",
                                        color: "#A1A7BD",
                                      },
                                    },
                                    [_vm._v("已有账号？")]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "#3476f0",
                                        "font-size": "14px",
                                        cursor: "pointer",
                                        display: "inline-block",
                                      },
                                      on: { click: _vm.onHandleToLogin },
                                    },
                                    [_vm._v("返回登录")]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
              ],
              2
            ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "隐私条款",
            visible: _vm.privacyClauseDialog,
            "append-to-body": "",
            width: "40%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.privacyClauseDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { height: "500px", "overflow-y": "auto" } },
            [
              _c("h2", [_vm._v("关于收集信息的声明")]),
              _c("p", { staticStyle: { "line-height": "24px" } }, [
                _vm._v(
                  " 鲸鱼可能向你收集有关你的个人或企业信息，你在注册账号或希望鲸针鱼平台服务时，我们可能收集的个人信息包括：姓名、电子邮件地址、电话号码、IP 地址；机构信息：机构/团队名称、Logo（或商标、机构标识）、机构/团队介绍、机构/团队通讯地址等相关信息，以及你在购买过程中可能会涉及到的帐户信息。 "
                ),
                _c("br"),
                _vm._v(
                  "鲸鱼可能会采用多种不同方式来接收和收集你的个人信息。这些方式可能包括： "
                ),
                _c("br"),
                _vm._v("（1）来自你的信件 "),
                _c("br"),
                _vm._v(
                  "你给我们发送电子邮件或写信时，可能会在邮件中提供你的公司/团队信息、你的姓名、联系详细信息以及其他个人信息。 "
                ),
                _c("br"),
                _vm._v("（2）技术服务 "),
                _c("br"),
                _vm._v(
                  "你在打电话或发电子邮件给我们请求产品方面的技术帮助或索取有关我们产品的信息时，可能向我们提供企业/团队和个人信息。 "
                ),
                _c("br"),
                _vm._v("（3）产品注册 "),
                _c("br"),
                _vm._v(
                  "注册鲸鱼产品时，系统会要求你提供信息，以便我们将你记录为鲸鱼产品的所有者，从而有助于我们为你提供服务和信息。当你浏览鲸鱼网站时，服务器会自动收集你的 IP 地址，此 IP 地址只被计算机用来向你发送相关的页面 , 帮助你监控非授权登录 "
                ),
                _c("br"),
                _vm._v("（4）第三方登陆 "),
                _c("br"),
                _vm._v(
                  "你通过第三方产品（如钉钉）使用鲸鱼时，系统会自动识别出你的企业信息，以便我们记录你为鲸鱼的使用者，从而有助于我们为你提供服务和信息。 "
                ),
                _c("br"),
                _vm._v("（5）购买 "),
                _c("br"),
                _vm._v(
                  "如果你希望通过我们的在线支付系统订购鲸鱼的产品，我们将需要足够的信息才能完成你的订购， 这些信息包括你企业/团队的银行账户信息或个人的信用卡信息。 "
                ),
              ]),
              _c("h2", [_vm._v("关于收集信息用途的声明")]),
              _c("p", { staticStyle: { "line-height": "24px" } }, [
                _vm._v(" 鲸鱼可能将你的个人信息用于以下用途： "),
                _c("br"),
                _vm._v("（1）产品注册 "),
                _c("br"),
                _vm._v(
                  "你注册时所提供的信息将用于创建你的鲸鱼账号，并且在你联系鲸鱼用户服务部门时， 这些信息还将协助鲸鱼为你提供支持。鲸鱼还将使用这些信息来通知你有关你可能感兴趣的任何升级、新产品、促销或其他信息。 "
                ),
                _c("br"),
                _vm._v("（2）产品的使用 "),
                _c("br"),
                _vm._v(
                  "鲸鱼可能将产品使用中收集的信息用于：定期验证你对产品的使用权利； 向你发送新产品/新功能升级的通知前，确认你是否正在使用该产品/功能的较早版本或者是否会对你使用鲸鱼有所帮助； 以及，从鲸鱼接收你请求获取的产品内部消息。 "
                ),
                _c("span", { staticStyle: { color: "red" } }, [
                  _c("br"),
                  _vm._v("（3）产品购买 "),
                  _c("br"),
                  _vm._v(
                    "如果你通过鲸鱼的在线支付系统购买产品，鲸鱼将使用你的信息来处理付款并将产品购买信息发送给你。 "
                  ),
                ]),
                _c("br"),
                _vm._v("（4）内部分析 "),
                _c("br"),
                _vm._v(
                  "我们可能会使用你提供给我们的信息进行内部统计和分析，从而评估并增强你鲸鱼网站的体验， 包括通过识别你的喜好和购买意向，进行营销以及与运营和开发相关的活动。 "
                ),
                _c("br"),
                _vm._v(
                  "如果你希望成为鲸鱼网站的用户，你必须注册并提供相应的信息。当你在鲸鱼网站注册帐户时，鲸鱼网站需要收集你的电子邮箱等 信息。当你浏览鲸鱼网站时，服务器会自动收集你的 IP 地址，此 IP 地址只被计算机用来向你发送相关的页面 , 帮助你监控非授权登录。 "
                ),
              ]),
              _c("h2", [_vm._v("自动收集的信息")]),
              _c("p", { staticStyle: { "line-height": "24px" } }, [
                _vm._v(
                  " 无论何时，只要你通过鲸鱼进入我们的网站或访问任何在线信息，我们的 Web 服务器就会自动收集和汇总有关你的访问的信息 （以下简称“自动信息”）。自动信息可能包括相关网站的 URL 或域、浏览器类型、操作系统、IP地址、你所访问的页面以及访问日期和时间。 "
                ),
              ]),
              _c("h2", [_vm._v("用户文件")]),
              _c("p", { staticStyle: { "line-height": "24px" } }, [
                _vm._v(
                  " 鲸鱼允许你存储、发送、接收、编辑、同步、共享或者以其他方式组织或管理文件和文件夹 （包括这些文件夹中存储的任何信息或数据）（以下统称“用户文件”）。 我们可能获得你在鲸鱼上储存的所有用户文件的大小，因为我们需要了解你对鲸鱼的实际使用情况。 "
                ),
              ]),
              _c("h2", [_vm._v("与第三方共享信息")]),
              _c("p", { staticStyle: { "line-height": "24px" } }, [
                _vm._v(
                  " 鲸鱼网站不会在未经合法用户授权时，公开、编辑或透露其信息及保存在鲸鱼网站中的非公开内容，除非在下述情况下部分或全部将被披露： "
                ),
                _c("br"),
                _vm._v("（1）服务提供商 "),
                _c("br"),
                _vm._v(
                  "我们有时会雇用其他公司代表我们提供服务，比如处理交易、邮件的处理和寄送、提供用户支持、 托管网站或者针对我们的产品或服务进行统计分析。我们有时需要将你企业/团队和个人的信息与其他公司共享， 以便这些公司能够提供适用的服务。我们仅向这些公司提供其向你提供服务所需的信息， 但未授权这些公司将你的信息用于鲸鱼之外的任何其他用途。 "
                ),
                _c("br"),
                _vm._v("（2）法定披露 "),
                _c("br"),
                _vm._v(
                  "根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；如果你出现违反中国有关法律法规的情况，需要向第三方披露；为提供你所要求的软件或服务，而必须和第三方分享你的数据； "
                ),
                _c("br"),
                _vm._v("（3）其它需要公开、编辑或透露用户信息的情况。 "),
              ]),
              _c("h2", [_vm._v("安全")]),
              _c("p", { staticStyle: { "line-height": "24px" } }, [
                _vm._v(
                  " 我们会采取合理的实际及电子手段以及规程保障措施来保护你的企业/团队和个人信息。 虽然通过因特网信息传输数据并非100% 安全，但我们已经采取并将继续采取商业范畴内合理的努力来确保你的个人信息得到保护。 "
                ),
              ]),
              _c("h2", [_vm._v("COOKIE")]),
              _c("p", { staticStyle: { "line-height": "24px" } }, [
                _vm._v(
                  " Cookie是仅限文本的信息字符串，网站会将这些字符串传输到计算机硬盘上浏览器的 Cookie 文件中， 以便网站能够记住你的身份信息以及其他详细信息。Cookie 可能由网站设置或来源自第三方，比如广告商。Cookie 本身不用于或意图用于从用户的计算机读取任何信息 （Cookie 本身的内容除外）。Cookie 只是网站所使用的最初将其放置在你硬盘上的标识符。同一台服务器可以检索到 Cookie 信息的实际内容， 从而标识计算机并进而根据主服务器上存储的信息自定义、跟踪或控制站点的使用情况。 "
                ),
                _c("br"),
                _vm._v(
                  "我们可能会在鲸鱼网站上使用 Cookie。使用 Cookie，我们便能进行自动访问和使用我们网站的数据输入功能，此外，我们还可以通过 Cookie 跟踪我们网站的使用情况，从而确定哪些功能有用或受欢迎，哪些功能并不能帮助我们有效地改进和更新我们的服务。 "
                ),
              ]),
              _c("h2", [_vm._v("第三方链接")]),
              _c("p", { staticStyle: { "line-height": "24px" } }, [
                _vm._v(
                  " 鲸鱼可能包含或链接至第三方提供的服务。该服务由第三方提供或运营，您使用第三方的服务以及向第三方提供的信息，将受该等第三方的用户协议和隐私条款约束，而不受本隐私政策的约束。我们对于我们链接到的其他网站或应用的内容或安全性不承担法律责任。 "
                ),
              ]),
              _c("h2", [_vm._v("儿童个人信息的保护")]),
              _c("p", { staticStyle: { "line-height": "24px" } }, [
                _vm._v(
                  " 一般来说，未经监护人的同意，我们不会主动收集和处理14岁以下儿童的个人信息。但是，由于技术限制，在某些情况下，我们提供在线产品和服务时无法识别用户的年龄。在这些情况下，根据相关法律，我们将用户视为有完全合法地权利向我们提供个人信息。如果我们无意中未经其监护人同意收集了任何儿童的个人信息，我们将在发现这种情况时或在监护人要求时及时删除。 "
                ),
              ]),
              _c("h2", [_vm._v("隐私政策的修订和通知")]),
              _c("p", { staticStyle: { "line-height": "24px" } }, [
                _vm._v(
                  " 1.为了给您提供更好的服务，鲸鱼及相关服务将不时更新与变化，我们会适时对本隐私政策进行修订，这些修订构成本隐私政策的一部分并具有等同于本隐私政策的效力，未经您明确同意，我们不会削减您依据当前生效的本隐私政策所应享受的权利。 "
                ),
                _c("br"),
                _vm._v(
                  "2.本隐私政策更新后，我们会在鲸鱼发出更新版本，并在更新后的条款生效前通过官方网站 公告或其他适当的方式提醒您更新的内容，以便您及时了解本隐私政策的最新版本。 "
                ),
                _c("br"),
                _vm._v(
                  "3.如果本隐私政策有较大变更，我们也将提供更明显的通知，如当您登录我们的应用时使用弹出窗口，或直接向您发送电子邮件等。本隐私政策的较大变更可能包括对处理个人信息的目的、处理的个人信息的类型以及使用个人信息的方式的重大更改，以及您对个人信息的权利或行使权利的方式的重大更改。 "
                ),
              ]),
            ]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.privacyClauseDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.privacyClauseDialog = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "服务条款",
            visible: _vm.serviceAgreementDialog,
            "append-to-body": "",
            width: "40%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.serviceAgreementDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { height: "500px", "overflow-y": "auto" } },
            [
              _c("h2", [_vm._v("特别说明")]),
              _c("p", { staticStyle: { "line-height": "24px" } }, [
                _c("span", [
                  _vm._v(
                    "本协议是鲸鱼用户（包括使用的个人和代表的企业）与鲸鱼软件之间的协议，用户注册鲸鱼服务即代表接受本协议的约束，并自注册成功之时即成为本协议一方，"
                  ),
                ]),
                _c("span", { staticStyle: { color: "red" } }, [
                  _vm._v(
                    "付费版用户签署本协议并回传（包括使用传真，电子邮件等电子通信手段）给鲸鱼即代表接受本协议中的计费和支付协议，并自发出协议之时即受该等协议约束。"
                  ),
                ]),
              ]),
              _c("h2", [_vm._v("账号注册")]),
              _c("p", { staticStyle: { "line-height": "24px" } }, [
                _vm._v(
                  " 1. 鲸鱼用户注册账号时应提交真实、准确、完整的信息，不得违反国家法律法规及本使用协议，未经他人许可不得使用他人名义注册账号，不得恶意注册账号。 "
                ),
                _c("br"),
                _vm._v(
                  "2. 账号的所有权及相关权益属于鲸鱼，用户不得以任何方式转让或向提供他人使用其使用的账号，否则鲸鱼有权立即不经通知收回该账号，由此带来的因用户使用鲸鱼产品产生的全部数据、信息等被清空、丢失等损失，用户应自行承担。 "
                ),
                _c("br"),
                _c("b", [
                  _vm._v(
                    "3. 鲸鱼用户应当对其账户下的一切行为负责，包括发布的任何内容以及由此产生的任何后果。鲸鱼不对用户使用鲸鱼产品服务而上传、存储或共享的内容承担任何责任。"
                  ),
                ]),
                _c("br"),
                _vm._v("4. 鲸鱼的用户可以通《隐私条款》中所述方式注销账户。 "),
                _c("br"),
                _vm._v(
                  "5. 鲸鱼用户注销账户的行为，将导致鲸鱼终止提供服务，也将终止给鲸鱼用户提供的外部门户服务。注销成功后，将删除用户的个人信息，使其保持不可被检索、访问的状态，或对其进行匿名化处理。如果用户仍执意注销账户，其账户需同时满足以下条件： "
                ),
                _c("br"),
                _vm._v("1) 账户中无资产、无欠款； "),
                _c("br"),
                _vm._v("2) 不存在已提供服务但未支付的功能/服务； "),
                _c("br"),
                _vm._v("3) 账户为正常使用中的账户且无任何账户被限制的记录； "),
                _c("br"),
                _vm._v("4) 账户下无任何纠纷，包括投诉举报和被投诉举报； "),
                _c("br"),
                _vm._v("5) 账户已经解除了与其他第三方账户的绑定。 "),
                _c("b", [
                  _c("br"),
                  _vm._v(
                    "6. 在鲸鱼账户注销期间，如果用户的账户涉及争议纠纷，包括但不限于投诉、举报、诉讼、仲裁、国家有权机关调查等,鲸鱼有权自行终止本账户的注销而无需另行获得鲸鱼用户的同意。 "
                  ),
                  _c("br"),
                  _vm._v(
                    "7. 请用户在提交注销申请前，务必解绑其他相关的第三方账户，具体操作方式可与我们的客服联系。 "
                  ),
                  _c("br"),
                  _vm._v(
                    "8. 账户一旦被注销将不可恢复，请用户在操作之前自行备份账户相关的所有信息和数据。注销账户，用户将无法再使用鲸鱼账户，也将无法找回用户的鲸鱼账户中及与账户相关的任何内容或信息（即使使用相同的手机号码再次注册并使用鲸鱼软件），包括但不限于： "
                  ),
                ]),
                _c("br"),
                _vm._v("1) 无法登录、使用鲸鱼账户； "),
                _c("br"),
                _vm._v("2) 鲸鱼账户的个人资料和历史信息都将无法找回； "),
                _c("br"),
                _c("b", [
                  _vm._v(
                    "9. 注销鲸鱼账户并不代表本账户注销前用户在该账户下的行为和相关责任得到豁免或减轻。"
                  ),
                ]),
              ]),
              _c("h2", [_vm._v("合法使用")]),
              _c("p", { staticStyle: { "line-height": "24px" } }, [
                _vm._v(
                  " 1. 鲸鱼服务限于提供给客户用作正常和合法业务工具，客户如果使用鲸鱼产品从事以下行为，将导致根本性违约，有权随时停止服务、解除本协议，并追讨因此带来的损失： "
                ),
                _c("br"),
                _vm._v("1) 客户使用鲸鱼用于违反法律的业务； "),
                _c("br"),
                _vm._v(
                  "2) 对鲸鱼产品进行了任何形式的对其他第三方的再授权使用，销售或转让； "
                ),
                _c("br"),
                _vm._v(
                  "3) 为设计开发竞争产品对鲸鱼产品进行任何形式的反向工程，或在竞争产品抄袭模仿的设计； "
                ),
                _c("br"),
                _vm._v("4) 滥用鲸鱼的通信功能发送垃圾邮件和短信； "),
                _c("br"),
                _vm._v(
                  "5) 对鲸鱼的连续服务和商誉构成损害的其他行为，包括对鲸鱼服务器的攻击。 "
                ),
                _c("br"),
                _c("span", { staticStyle: { color: "red" } }, [
                  _vm._v(
                    "2. *使用客户所拥有的手机号作为用户权证的唯一识别信息。当客户不再拥有在登记的手机号时，有权终止提供服务，客户亦可以通过变更手机号的方式继续使用服务。在付费版下，系统支持多个域名电子邮件地址，当最早登记的主域名权属发生改变时，*有权终止提供服务。"
                  ),
                ]),
                _c("br"),
                _vm._v(
                  "3. 鲸鱼用户须对自己在网上的言论和行为承担法律责任，若在鲸鱼产品上散布和传播反动、色情或其它违反国家法律的信息，本公司的系统记录有可能作为用户违反法律的证据。 "
                ),
                _c("br"),
                _vm._v(
                  "4. 本使用协议依据国家相关法律法规规章制定，用户同意严格遵守以下义务： "
                ),
                _c("br"),
                _vm._v(
                  "（1）不得利用鲸鱼产品从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动； "
                ),
                _c("br"),
                _vm._v(
                  "（2）不得干扰鲸鱼产品的正常运转，不得产品及国家计算机信息系统； "
                ),
                _c("br"),
                _vm._v(
                  "（3）不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的、不文明的等信息； "
                ),
                _c("br"),
                _vm._v(
                  "（4）不得教唆他人从事违法违规或本使用协议所禁止的行为； "
                ),
                _c("br"),
                _vm._v(
                  "（5）不得利用在鲸鱼注册的账户买卖进行牟利性经营活动； "
                ),
                _c("br"),
                _vm._v(
                  "（6）不得发布任何侵犯他人个人信息、著作权、商标权等知识产权或合法权利的内容； "
                ),
                _c("br"),
                _vm._v(
                  "5. 除非法律允许或本公司书面许可，使用鲸鱼产品过程中不得从事下列行为： "
                ),
                _c("br"),
                _vm._v("（1）删除鲸鱼产品及其副本上关于著作权的信息； "),
                _c("br"),
                _vm._v(
                  "（2）对鲸鱼产品进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现产品的源代码； "
                ),
                _c("br"),
                _vm._v(
                  "（3）对鲸鱼拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等； "
                ),
                _c("br"),
                _vm._v(
                  "（4）对鲸鱼产品或其运行过程中释放到任何终端内存中的数据、运行过程中客户端与服务器端的交互数据，以及对鲸鱼产品运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经授权的第三方工具/服务接入产品和相关系统； "
                ),
                _c("br"),
                _vm._v(
                  "（5）通过修改或伪造鲸鱼产品运行中的指令、数据，增加、删减、变动鲸鱼产品的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的； "
                ),
                _c("br"),
                _vm._v(
                  "（6）通过非鲸鱼开发、授权的第三方软件、插件、外挂、系统，登录或使用鲸鱼产品及服务，或制作、发布、传播上述工具； "
                ),
                _c("br"),
                _vm._v(
                  "（7）自行或者授权他人、第三方软件对鲸鱼产品及其组件、模块、数据进行干扰。 "
                ),
                _c("br"),
                _vm._v(
                  "6. 鲸鱼用户不得制作、传输或发表以下违法信息：反对宪法所确定的基本原则的；危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；损害国家荣誉和利益的；歪曲、丑化、亵渎、否定英雄烈士事迹和精神，以侮辱、诽谤或者其他方式侵害英雄烈士的姓名、肖像、名誉、荣誉的；宣扬恐怖主义、极端主义或者煽动实施恐怖活动、极端主义活动的；煽动民族仇恨、民族歧视，破坏民族团结的；破坏国家宗教政策，宣扬邪教和封建迷信的；散布谣言，扰乱经济秩序和社会秩序的；散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；侮辱或者诽谤他人，侵害他人名誉、隐私和其他合法权益的；法律、行政法规禁止的其他内容。 "
                ),
                _c("br"),
                _vm._v(
                  "7. 鲸鱼用户必须防范和抵制制作、复制、发布含有下列内容的不良信息：使用夸张标题，内容与标题严重不符的；炒作绯闻、丑闻、劣迹等的；不当评述自然灾害、重大事故等灾难的；带有性暗示、性挑逗等易使人产生性联想的；展现血腥、惊悚、残忍等致人身心不适的；煽动人群歧视、地域歧视等的；宣扬低俗、庸俗、媚俗内容的；可能引发未成年人模仿不安全行为和违反社会公德行为、诱导未成年人不良嗜好等的；其他对网络生态造成不良影响的内容。 "
                ),
              ]),
              _c("h2", [_vm._v("版权声明")]),
              _c("p", { staticStyle: { "line-height": "24px" } }, [
                _vm._v(
                  " 以任何方式使用鲸鱼提供的服务，包括但不限于基于鲸鱼搭建的数据管理应用等服务，所涉及的版权信息的解释权归鲸鱼所有。鲸鱼有权为推广你发布到模板中心的应用使用你的Logo，商标、字号、应用描述等信息。网站的所有内容版权属于鲸鱼网站权利人所有，严禁未经鲸鱼网站权利人书面许可的任何形式的部分或全部拷贝使用。版权所有翻版必究。中的应用程序、源代码、LOGO、界面设计、应用程序编程接口（API）所关联的所有知识产权均归属帆软软件有限公司所有。 除另有特别声明外，帆软提供本服务时所依托软件的著作权、专利权及其他知识产权均归帆软所有。上述及其他任何本服务包含的内容的知识产权均受到法律保护，未经帆软同意、用户或相关权利人书面许可， 任何人不得以任何形式进行使用或创造相关衍生作品。 "
                ),
              ]),
              _c("h2", [_vm._v("法律适用和争议解决")]),
              _c("p", { staticStyle: { "line-height": "24px" } }, [
                _vm._v(
                  " （1）鲸鱼与帐户中任何一方未履行协议所规定的责任均视为违约，按《合同法》及其相关司法解释规定处理；如双方在此协议范围内发生纠纷，应尽量友好协商解决。此协议适用中华人民共和国法律（不包括冲突法）。 "
                ),
                _c("br"),
                _vm._v(
                  "（2）如协商不成的，你同意将纠纷或争议提交乙方所在地有管辖权的人民法院管辖。如与此协议有关的某一特定事项缺乏明确法律规定，则应参照通用的国际商业惯例和行业惯例。本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。 "
                ),
              ]),
              _c("h2", [_vm._v("关于收费服务")]),
              _c("p", { staticStyle: { "line-height": "24px" } }, [
                _vm._v(
                  " 1. 除鲸鱼确规定收费的服务项目外，鲸鱼是免费注册、使用的。 "
                ),
                _c("br"),
                _vm._v(
                  "2. 用户知晓并同意鲸鱼有权根据实际的需要对某些目前免费的服务项目收取合理的服务费，如用户不同意轻流收取此项目费用，则用户有权退出使用该项服务或终止使用鲸鱼。 "
                ),
                _c("br"),
                _vm._v(
                  "3. 用户理解并同意轻流对新增服务项目有权决定是否收费，用户有权选择接受或拒绝该项目服务。 "
                ),
                _c("br"),
                _vm._v(
                  "4. 如用户接受轻流的收费项目，则用户应当按鲸鱼的收费规定支付费用；如用户未支付费用或欠费，则轻流有权不向用户提供此项服务或停止向用户提供此项服务。 "
                ),
                _c("br"),
                _vm._v(
                  "5. 鲸鱼有权根据实际需要调整收费项目的资费，用户有权选择接受或拒绝用。 "
                ),
              ]),
              _c("h2", [_vm._v("版权声明")]),
              _c("p", { staticStyle: { "line-height": "24px" } }, [
                _vm._v(
                  " 以任何方式使用鲸鱼提供的服务，包括但不限于基于鲸鱼搭建的数据管理应用等服务，所涉及的版权信息的解释权归鲸鱼所有。鲸鱼有权为推广你发布到模板中心的应用使用你的Logo，商标、字号、应用描述等信息。网站的所有内容版权属于鲸鱼网站权利人所有，严禁未经鲸鱼网站权利人书面许可的任何形式的部分或全部拷贝使用。版权所有翻版必究。中的应用程序、源代码、LOGO、界面设计、应用程序编程接口（API）所关联的所有知识产权均归属帆软软件有限公司所有。 除另有特别声明外，帆软提供本服务时所依托软件的著作权、专利权及其他知识产权均归帆软所有。上述及其他任何本服务包含的内容的知识产权均受到法律保护，未经帆软同意、用户或相关权利人书面许可， 任何人不得以任何形式进行使用或创造相关衍生作品。 "
                ),
              ]),
              _c("h2", [_vm._v("责任限制")]),
              _c("p", { staticStyle: { "line-height": "24px" } }, [
                _vm._v(
                  " 1.服务将按照“现状”和“可得到”的状态提供。鲸鱼在此明确声明，除本协议有明确约定外，鲸鱼对服务不作任何明示或暗示的保证，包括但不限于对服务的适用性、准确性、持续性、可靠性、服务没有错误或疏漏等。 "
                ),
                _c("br"),
                _vm._v(
                  "2.不论在何种情况下，不对由于网络连接故障、电脑或系统故障、电力故障、罢工、暴乱、火灾、洪水、爆炸、战争、政府行为、疫情等情况造成的不能服务或延迟服务承担任何责任。 "
                ),
                _c("br"),
                _vm._v(
                  "3.鲸鱼不对任何间接的、惩罚性的、突发性的损害或利益损失承担责任。 "
                ),
                _c("br"),
                _vm._v(
                  "4.如因用户违反本协议、法律法规要求、用户未能履行付款义务或用户注销账户等造成数据丢失、删除、毁损的，鲸鱼不承担任何责任，用户应自行负责对数据进行备份。 "
                ),
                _c("b", [
                  _c("br"),
                  _vm._v("5.在法律允许的范围内，对因： "),
                  _c("br"),
                  _vm._v(
                    "（1）鲸鱼产品受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏； "
                  ),
                  _c("br"),
                  _vm._v(
                    "（2）鲸鱼用户操作不当或用户通过非授权的方式使用本服务； "
                  ),
                  _c("br"),
                  _vm._v("（3）程序版本过时、设备的老化和/或其兼容性问题； "),
                  _c("br"),
                  _vm._v(
                    "（4）其他鲸鱼无法控制或合理预见的情形，导致的鲸鱼服务中断或终止，不承担赔偿责任。 "
                  ),
                ]),
              ]),
              _c("h2", [_vm._v("联系我们")]),
              _c("p", { staticStyle: { "line-height": "24px" } }, [
                _vm._v(
                  " 如果您对本协议或鲸鱼软件有任何意见或建议，可通过邮箱与我们取得联系，我们会给予您必要的帮助。 "
                ),
              ]),
            ]
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.serviceAgreementDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.serviceAgreementDialog = false
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }